import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { isAuthenticated, redirectToLogin } from 'App/utils/auth'
import urls from 'App/utils/urls'
import profilesTypes from 'App/enums/profilesTypes'
import useDocumentTitle from 'App/hooks/useDocumentTitle'

const appName = process.env.REACT_APP_NAME

/**
 * A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 */
const PrivateRoute = ({
  component: Component,
  title,
  path,
  redirect,
  ...remainingProps
}) => {
  const loggedUser = useSelector((state) => state.users.loggedUser)
  const history = useHistory()

  const documentTitle = title !== appName
    ? `${title} · ${appName}`
    : title

  useDocumentTitle(documentTitle)

  useEffect(() => {
    const allowedProfiles = [
      profilesTypes.admin,
      profilesTypes.opcom,
      profilesTypes.educational,
      profilesTypes.helpdesk,
    ]

    if (loggedUser.profileId && !allowedProfiles.includes(loggedUser.profileId)) {
      window.location.assign(urls.PORTAL_URL)
    }
  }, [
    history,
    loggedUser,
  ])

  if (!isAuthenticated()) {
    redirectToLogin()

    return null
  }

  return (
    <Route
      {...remainingProps}
      path={path}
      render={(props) => (redirect ? (<Redirect to={redirect} />) : (<Component {...props} />))}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    // lazy loaded component
    PropTypes.shape({}),
    PropTypes.element,
  ]),
  path: PropTypes.string.isRequired,
  redirect: PropTypes.string,
  title: PropTypes.string,
}

PrivateRoute.defaultProps = {
  component: null,
  redirect: null,
  title: appName,
}

export default PrivateRoute
