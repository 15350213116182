import I18n from 'App/i18n'

import profilesTypes from 'App/enums/profilesTypes'

export const admin = {
  name: I18n.t('commons.profiles.admin'),
  id: profilesTypes.admin,
}
export const student = {
  name: I18n.t('commons.profiles.student'),
  id: profilesTypes.student,
}
export const teacher = {
  name: I18n.t('commons.profiles.teacher'),
  id: profilesTypes.teacher,
}
export const consultant = {
  name: I18n.t('commons.profiles.consultant'),
  id: profilesTypes.consultant,
}
export const guardian = {
  name: I18n.t('commons.profiles.guardian'),
  id: profilesTypes.guardian,
}
export const school = {
  name: I18n.t('commons.profiles.school'),
  id: profilesTypes.school,
}
export const helpdesk = {
  name: I18n.t('commons.profiles.helpdesk'),
  id: profilesTypes.helpdesk,
}
export const financialManager = {
  name: I18n.t('commons.profiles.financialManager'),
  id: profilesTypes.financialManager,
}
export const contentAdmin = {
  name: I18n.t('commons.profiles.contentAdmin'),
  id: profilesTypes.contentAdmin,
}
export const orderStore = {
  name: I18n.t('commons.profiles.orderStore'),
  id: profilesTypes.orderStore,
}
export const coordinator = {
  name: I18n.t('commons.profiles.coordinator'),
  id: profilesTypes.coordinator,
}
export const schoolAdministration = {
  name: I18n.t('commons.profiles.schoolAdministration'),
  id: profilesTypes.schoolAdministration,
}
export const provider = {
  name: I18n.t('commons.profiles.provider'),
  id: profilesTypes.provider,
}
export const manager = {
  name: I18n.t('commons.profiles.manager'),
  id: profilesTypes.manager,
}
export const production = {
  name: I18n.t('commons.profiles.production'),
  id: profilesTypes.sasProduction,
}
