import PropTypes from 'prop-types'

import { sizes } from 'App/utils'

export default {
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  size: PropTypes.oneOf(sizes),
  text: PropTypes.string,
}
