import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import I18n from 'i18n-js'

import profilesTypes from 'App/enums/profilesTypes'
import useStoreUsers from 'Users/store/useStoreUsers'

import logoSrc from 'App/assets/images/administrativo-acessos-logo.svg'

import UserMenu from './UserMenu'

import styles from './Menu.module.scss'

const links = [
  {
    name: I18n.t('app.menu.schools'),
    path: '/',
    exact: true,
    allowedProfiles: [
      profilesTypes.admin,
      profilesTypes.opcom,
      profilesTypes.educational,
    ],
  },
]

const Menu = () => {
  const loggedUser = useSelector((state) => state.users.loggedUser)

  const [
    menuItems,
    setMenuItems,
  ] = useState([])
  const [
    isLoading,
    setLoading,
  ] = useState(false)
  const [
    hasError,
    setError,
  ] = useState(false)

  const { getUserInfo } = useStoreUsers()

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true)
      setError(false)

      try {
        await getUserInfo()
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchUser()
  }, [getUserInfo])

  useEffect(() => {
    if (loggedUser.profileId === profilesTypes.admin) {
      setMenuItems(links)
    } else {
      setMenuItems(links.filter((link) => link
        .allowedProfiles
        .includes(loggedUser.profileId)))
    }
  }, [loggedUser])

  return (
    <nav className={styles.nav}>
      <NavLink
        className={styles.logo}
        title={I18n.t('app.title')}
        to="/"
      >
        <img
          alt={I18n.t('app.title')}
          className={styles.logoImg}
          src={logoSrc}
        />
      </NavLink>

      <ul
        className={styles.menu}
        role="menubar"
      >
        {menuItems.map((item) => (
          <li
            key={item.name}
            role="none"
          >
            <NavLink
              activeClassName={styles.active}
              className={styles.item}
              exact={item.exact}
              role="menuitem"
              to={item.path}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>

      <UserMenu
        hasError={hasError}
        isLoading={isLoading}
      />
    </nav>
  )
}

export default Menu
