import PropTypes from 'prop-types'
import clsx from 'clsx'

import { LARGE } from 'App/utils'

import Button from 'App/components/Button'

import styles from './DropdownItem.module.scss'

const DropdownItem = ({ children, color, ...remainingProps }) => {
  const colorClasses = {
    default: styles.colorDefault,
    primary: styles.colorPrimary,
    danger: styles.colorDanger,
  }

  return (
    <Button
      className={clsx(
        styles.item,
        colorClasses[color],
        'sas-dropdown-item',
      )}
      size={LARGE}
      variation="tertiary"
      hasFullWidth
      {...remainingProps}
    >
      {children}
    </Button>
  )
}

DropdownItem.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'danger',
  ]),
}

DropdownItem.defaultProps = {
  children: null,
  color: 'default',
}

export default DropdownItem
