import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ReactTooltip from 'react-tooltip'

import { variations } from 'App/utils'

import styles from './Tooltip.module.scss'

const Tooltip = forwardRef(function Tooltip({
  children,
  className,
  effect,
  id,
  type,
  ...remainingProps
}, ref) {
  const classes = {
    type: `--${type}`,
  }

  return (
    <ReactTooltip
      ref={ref}
      className={clsx(
        styles.tooltip,
        styles[classes.type],
        classes.type,
        'sas-tooltip',
      )}
      effect={effect}
      id={id}
      type={type}
      {...remainingProps}
    >
      {children}
    </ReactTooltip>
  )
})

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  effect: PropTypes.oneOf([
    'solid',
    'float',
  ]),
  id: PropTypes.string,
  type: PropTypes.oneOf([
    'dark',
    ...variations,
  ]),
}

Tooltip.defaultProps = {
  className: null,
  effect: 'solid',
  id: null,
  type: 'dark',
}

export default Tooltip
