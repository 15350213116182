const production = {
  AUTH_API: 'https://auth-server.portalsaseducacao.com.br',
  PORTAL_API: 'https://api.portalsaseducacao.com.br/v1',
  PORTAL_URL: 'https://portalsaseducacao.com.br',
  // sasdigital.com.br
  BASE_API: 'https://school-admin-bff.sasdigital.com.br/v1',
}

const staging = {
  AUTH_API: 'https://auth-server.staging.portalsaseducacao.com.br',
  PORTAL_API: 'https://api.staging.portalsaseducacao.com.br/v1',
  PORTAL_URL: 'https://portal.staging.portalsaseducacao.com.br',
  // sasdigital.com.br
  BASE_API: 'https://school-admin-bff.staging.sasdigital.com.br/v1',
}

const development = {
  ...staging,
  PORTAL_URL: 'http://localhost:8080',
}

const urlsByMode = {
  production,
  staging,
  development,
}

const urls = urlsByMode[process.env.REACT_APP_MODE]

export default urls
