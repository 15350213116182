import SkeletonLoader from 'App/components/SkeletonLoader'

import styles from './UserMenuSkeletonLoader.module.scss'

const UserMenuSkeletonLoader = () => (
  <div className={styles.user}>
    <SkeletonLoader
      className={styles.avatar}
      height="44px"
      width="44px"
    />

    <div>
      <SkeletonLoader
        className={styles.profileName}
        height="8px"
        width="45px"
      />

      <div className={styles.info}>
        <SkeletonLoader
          className={styles.userName}
          height="12px"
          width="150px"
        />

        <SkeletonLoader
          height="12px"
          width="80px"
        />
      </div>
    </div>
  </div>
)

export default UserMenuSkeletonLoader
