const ptBR = {
  schools: {
    list: {
      listTotal: '{{total}} Escolas',
    },
    details: {
      school: 'Escola',
      card: {
        title: 'Informações',
      },
      infoCard: {
        code: 'Cód. Protheus',
        type: 'Conta',
        typeDescription: {
          CONTRACTED: 'Conveniada',
          PROSPECTION: 'Alvo',
        },
        typeStatus: {
          REGULAR: 'Regular',
          PENDING: 'Pendente',
          CRM_ERROR: 'Erro no CRM',
          PROTHEUS_ERROR: 'Erro no Protheus',
          NO_KITS: 'Sem kits',
        },
      },
      accessCard: {
        change: 'Alterar',
        createAccess: 'Criar acesso',
        email: 'E-mail',
        masterAccess: 'Acesso Principal',
        noAccessMsg: 'Essa escola está sem acesso principal. 😔',
        password: 'Senha',
        passwordMsg: '•••••••••••••••',
        login: 'Login',
      },
      drawerConflicts: {
        title: 'Encontramos o e-mail sendo utilizado',
        usedEmail: 'Outros usuários estão utilizando esse e-mail: {{email}}',
        updateData: 'Assim os dados de login e senha dos usuários serão'
          + ' atualizados para os dados novos cadastrados.',
        confirm: 'Confirmar',
        columsTable: {
          protheus: 'Cód. Protheus',
          name: 'Nome',
          perfil: 'Perfil',
          status: 'Status',
        },
      },
      status: {
        DELETED: 'Deletado',
        EXPIRED: 'Expirado',
        ACTIVE: 'Ativo',
        INACTIVE: 'Inativo',
      },
    },
    kitsList: {
      digital: '(digital)',
      gateAccess: '(acesso ao portal)',
      grade: 'Ano/Série',
      contracted: 'Contratados',
      extras: 'extras',
      total: 'Total',
      used: 'Utilizados',
      avaliable: 'Total disponível: {{kits}}',
    },
  },
}

export default ptBR
