import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './SkeletonLoader.module.scss'

const SkeletonLoader = forwardRef(function SkeletonLoader({
  className,
  borderRadius,
  height,
  opacity,
  width,
  ...remainingProps
}, ref) {
  return (
    <div
      ref={ref}
      className={clsx(
        styles.loader,
        'sas-skeleton-loader',
        className,
      )}
      style={{
        borderRadius,
        height,
        opacity,
        width,
      }}
      {...remainingProps}
    />
  )
})

SkeletonLoader.propTypes = {
  borderRadius: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.string,
  opacity: PropTypes.number,
  width: PropTypes.string,
}

SkeletonLoader.defaultProps = {
  borderRadius: null,
  className: null,
  height: null,
  opacity: 1,
  width: '100%',
}

export default SkeletonLoader
