import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  AlertTriangle as AlertIcon,
  Check as CheckIcon,
  AlertCircle as DangerIcon,
  Info as InfoIcon,
} from 'react-feather'

import { variations } from 'App/utils'

import styles from './Snackbar.module.scss'

const Snackbar = forwardRef(function Snackbar({ children, variation, className }, ref) {
  const classes = {
    primary: styles.isPrimary,
    success: styles.isSuccess,
    warning: styles.isWarning,
    danger: styles.isDanger,
  }

  const getIcon = () => {
    switch (variation) {
    case 'success':
      return <CheckIcon className={styles.icon} />
    case 'warning':
      return <AlertIcon className={styles.icon} />
    case 'danger':
      return <DangerIcon className={styles.icon} />
    default:
      return <InfoIcon className={styles.icon} />
    }
  }

  return (
    <div
      ref={ref}
      className={clsx(
        styles.snackbar,
        classes[variation],
        className,
      )}
    >
      {getIcon()}

      <span className={styles.text}>{children}</span>
    </div>
  )
})

Snackbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variation: PropTypes.oneOf(variations),
}

Snackbar.defaultProps = {
  className: null,
  variation: 'primary',
}

export default Snackbar
