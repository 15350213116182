import { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import I18n from 'i18n-js'

import {
  MEDIUM,
  sizes,
  variations,
} from 'App/utils'

import Spinner from 'App/components/Spinner'

import styles from './Button.module.scss'

const Button = forwardRef(function Button({
  isActive,
  children,
  className,
  icon,
  isDisabled,
  hasFullWidth,
  href,
  prepend,
  append,
  isLoading,
  size,
  to,
  type,
  variation,
  ...remainingProps
}, ref) {
  const buttonProps = {}
  const classes = {
    isActive: '--active',
    hasFullWidth: '--full-width',
    iconOnly: '--icon-only',
    isLoading: '--loading',
    size: `--${size}`,
    variation: `--${variation}`,
  }

  let ButtonBase = 'button'

  if (href) {
    buttonProps.href = href
    ButtonBase = 'a'
  } else if (to) {
    buttonProps.to = to
    ButtonBase = NavLink
  } else {
    buttonProps.type = type
    buttonProps.disabled = isDisabled || isLoading
  }

  return (
    <ButtonBase
      ref={ref}
      className={clsx(
        styles.button,
        styles[classes.size],
        classes.size,
        styles[classes.variation],
        classes.variation,
        icon && [
          styles[classes.iconOnly],
          classes.iconOnly,
        ],
        isActive && [
          styles[classes.isActive],
          classes.isActive,
        ],
        isLoading && [
          styles[classes.isLoading],
          classes.isLoading,
        ],
        hasFullWidth && [
          styles[classes.hasFullWidth],
          classes.hasFullWidth,
        ],
        'sas-button',
        className,
      )}
      {...buttonProps}
      {...remainingProps}
    >
      {isLoading ? (
        <>
          <Spinner
            className={styles.spinner}
            customSize="75%"
          />

          <span
            aria-busy="true"
            className="srOnly"
            role="alert"
          >
            {I18n.t('commons.loading')}
          </span>
        </>
      ) : (
        <>
          {!icon && prepend && (
            <span className={styles.prepend}>
              {prepend}
            </span>
          )}

          <span className={styles.buttonText}>
            {icon || children}
          </span>

          {!icon && append && (
            <span className={styles.append}>
              {append}
            </span>
          )}
        </>
      )}
    </ButtonBase>
  )
})

Button.propTypes = {
  append: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  hasFullWidth: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.node,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  prepend: PropTypes.node,
  size: PropTypes.oneOf(sizes),
  to: PropTypes.string,
  type: PropTypes.oneOf([
    'submit',
    'reset',
    'button',
  ]),
  variation: PropTypes.oneOf([
    'secondary',
    'tertiary',
    'link',
    ...variations,
  ]),
}

Button.defaultProps = {
  append: null,
  children: null,
  className: null,
  hasFullWidth: false,
  href: null,
  icon: null,
  isActive: false,
  isDisabled: false,
  isLoading: false,
  prepend: null,
  size: MEDIUM,
  to: null,
  type: 'button',
  variation: 'primary',
}

export default Button
