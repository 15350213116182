import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { MEDIUM, sizes } from 'App/utils'

import styles from './Spinner.module.scss'

const Spinner = forwardRef(function Spinner({
  className,
  color,
  customSize,
  size,
  strokeWidth,
  ...remainingProps
}, ref) {
  const classes = {
    size: `--${size}`,
  }

  return (
    <svg
      ref={ref}
      className={clsx(
        styles.spinner,
        !customSize && [
          styles[classes.size],
          classes.size,
        ],
        'sas-spinner',
        className,
      )}
      stroke={color}
      strokeWidth={strokeWidth}
      style={{
        height: customSize,
      }}
      viewBox="25 25 50 50"
      {...remainingProps}
    >
      <circle />
    </svg>
  )
})

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  customSize: PropTypes.string,
  size: PropTypes.oneOf(sizes),
  strokeWidth: PropTypes.number,
}

Spinner.defaultProps = {
  className: null,
  color: 'currentColor',
  customSize: null,
  size: MEDIUM,
  strokeWidth: 3,
}

export default Spinner
