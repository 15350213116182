const keyCodes = {
  backSpace: 8,
  delete: 46,
  down: 40,
  end: 35,
  esc: 27,
  home: 36,
  left: 37,
  pageDown: 34,
  pageUp: 33,
  return: 13,
  right: 39,
  space: 32,
  tab: 9,
  up: 38,
  zero: 48,
  nine: 57,
}

export default Object.freeze(keyCodes)
