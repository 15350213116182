import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import api from 'App/api'
import urls from 'App/utils/urls'

import {
  SET_LOGGED_USER,
} from './actionsTypes'

const useStoreUsers = () => {
  const dispatch = useDispatch()

  const getUserInfo = useCallback(async () => {
    const { data } = await api.get(`${urls.AUTH_API}/v2/personal-data`)

    const {
      user,
      profileId,
      profileName,
      schoolName,
    } = data

    const {
      profilePicture,
      name,
    } = user

    dispatch({
      type: SET_LOGGED_USER,
      payload: {
        profilePicture,
        name,
        profileId,
        profileName,
        schoolName,
      },
    })
  }, [dispatch])

  return {
    getUserInfo,
  }
}

export default useStoreUsers
