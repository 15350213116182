import { compose, createStore } from 'redux'
import rootReducer from './rootReducer'

/* eslint-disable no-underscore-dangle */
const composeEnhancers = process.env.NODE_ENV === 'development'
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose
/* eslint-enable */

const store = createStore(rootReducer, composeEnhancers())

export default store
