import users from 'Users/locales/ptBR'
import schools from 'Schools/locales/ptBR'

const ptBR = {
  app: {
    title: 'SAS Educação Administrativo de Acessos',
    menu: {
      schools: 'Escolas',
    },
    userMenu: {
      messages: {
        failedGetInfo: 'Falha ao carregar informações de usuário',
      },
    },
  },
  commons: {
    actions: {
      back: 'Voltar',
      configurations: 'Configurações',
      delete: 'Excluir',
      deleteSelecteds: 'Excluir selecionados',
      deleteConfirmation: 'Sim, excluir',
      deleteConfirmationQuestion: 'Deseja realmente excluir?',
      edit: 'Editar',
      exportXls: 'Exportar .xls',
      register: 'Cadastrar',
      showDetails: 'Ver detalhes',
      showFinished: 'Ver concluídas',
      reload: 'Recarregar',
      logout: 'Sair',
      seeDetails: 'Ver detalhes',
      saveChanges: 'Salvar alterações',
      tryAgain: 'Tentar novamente',
      update: 'Atualizar',
      search: 'Pesquisar',
      continue: 'Continuar',
      clear: 'Limpar',
      filter: 'Filtrar',
      yes: 'Sim',
      no: 'Não',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      prev: 'Anterior',
      next: 'Próximo',
      view: 'Visualizar',
      createAcess: 'Criar acesso',
      updating: 'Atualizando',
      confirmChanges: 'Confirmar alterações',
    },
    profiles: {
      admin: 'Administrador',
      student: 'Aluno',
      teacher: 'Professor',
      consultant: 'Consultor',
      guardian: 'Tutor',
      school: 'Escola',
      helpdesk: 'Suporte',
      financialManager: 'Gerente Financeiro',
      contentAdmin: 'Conteúdo',
      orderStore: 'Logistica',
      coordinator: 'Coordenador',
      schoolAdministration: 'Secretaria',
      provider: 'Fornecedor',
      manager: 'Gerenciamento',
      production: 'Produção',
    },
    form: {
      required: 'Campo obrigatório!',
      error: 'Ocorreu um erro ao enviar o formulário',
      itemTitlePlaceholder: 'Título do Item',
      select: 'Selecione',
    },
    errors: {
      generic: 'Aconteceu um erro inesperado. Se persistir, entre em contato com o suporte',
      emptyForNow: 'Não há nada aqui ainda',
    },
    api: {
      createItemSuccessMessage: 'Item foi criado com sucesso',
      deleteItemSuccessMessage: 'Item foi removido com sucesso',
      updateItemSuccessMessage: 'Item foi atualizado com sucesso',
      loading: 'Carregando',
    },
    list: {
      resultsTitle: 'Resultados',
      emptyMessage: 'Não encontramos nenhum item',
      emptyMessageWithFilter: 'Não encontramos nenhum item para os termos pesquisados',
      noResultsFound: 'Nenhum resultado encontrado',
      noResultsFoundForTerm: 'Nenhum resultado encontrado para "{{term}}"',
    },
    filters: {
      filterBy: 'Filtrar por{{append}}',
    },
    loading: 'Carregando',
    amountOfTotal: '{{amount}} de {{total}}',
    CRMAdivice: 'Alterações do CRM e ERP podem demorar até 2h para serem atualizadas.',
    grades: {
      kindergartenII: 'Infantil II',
      kindergartenIII: 'Infantil III',
      kindergartenIV: 'Infantil IV',
      kindergartenV: 'Infantil V',
      elementarySchool1: '1º ano',
      elementarySchool2: '2º ano',
      elementarySchool3: '3º ano',
      elementarySchool4: '4º ano',
      elementarySchool5: '5º ano',
      elementarySchool6: '6º ano',
      elementarySchool7: '7º ano',
      elementarySchool8: '8º ano',
      elementarySchool9: '9º ano',
      highSchool1: '1ª série',
      highSchool2: '2ª série',
      preUni: 'Pré-Universitário',
      preEntranceExam: 'Pré-Vestibular',
      semi: 'Semiextensivo',
      semi1: 'Semiextensivo 1º Semestre',
      semi2: 'Semiextensivo 2º Semestre',
    },
    pagination: {
      first: 'Primeira página',
      last: 'Última página',
    },
  },
  ...users,
  ...schools,
}

export default ptBR
