import { isNotEmpty } from './array'

export const hasOwnProperty = (object, prop) => Object
  .prototype
  .hasOwnProperty
  .call(object, prop)

export const filterObjectKeys = (object, keys) => {
  const filtered = {}

  Object.keys(object).forEach((key) => {
    if (keys.includes(key)) {
      filtered[key] = object[key]
    }
  })

  return filtered
}

export const simpleObjectDifference = (object, values, keeps = []) => {
  const difference = {}

  Object.keys(values).forEach((key) => {
    if (values[key] !== object[key] || keeps.includes(key)) {
      difference[key] = values[key]
    }
  })

  return difference
}

export const removeEmptyProps = (obj) => {
  const newObject = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    const isObject = typeof value === 'object'
      && value !== null
      && !Array.isArray(value)

    if (
      typeof value === 'string'
      || typeof value === 'number'
      || isObject
      || isNotEmpty(value)
    ) {
      newObject[key] = value
    }
  })

  return newObject
}
