import schoolsRoutes from 'Schools/routes'

export default [
  ...schoolsRoutes,
  {
    path: '/',
    name: 'home',
    redirect: '/escolas',
    exact: true,
  },
]
