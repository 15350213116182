// Sizes
export const SMALL = 'small'
export const MEDIUM = 'medium'
export const LARGE = 'large'
export const sizes = [
  SMALL,
  MEDIUM,
  LARGE,
]

// Status
export const SUCCESS = 'success'
export const WARNING = 'warning'
export const ERROR = 'error'
export const status = [
  SUCCESS,
  ERROR,
  WARNING,
]

export const PRIMARY = 'primary'
export const DANGER = 'danger'
export const variations = [
  PRIMARY,
  SUCCESS,
  WARNING,
  DANGER,
]
