import { Suspense } from 'react'
import {
  Redirect,
  Switch,
} from 'react-router-dom'
import { toast } from 'react-toastify'

import routes from 'App/router/routes'

import Menu from 'App/components/Menu'
import PrivateRoute from 'App/router/PrivateRoute'

import './i18n'

import 'react-toastify/dist/ReactToastify.css'
import './index.scss'

toast.configure({
  position: toast.POSITION.BOTTOM_LEFT,
  closeOnClick: true,
  pauseOnHover: true,
  autoClose: 5000,
})

const App = () => (
  <>
    <Menu />

    {/* TODO: add loading */}

    <Suspense fallback={<div />}>
      <Switch>
        {routes.map((route) => (
          <PrivateRoute
            key={route.name}
            {...route}
          />
        ))}

        {/* TODO: add 404 route */}

        <Redirect
          from="*"
          to="/"
        />
      </Switch>
    </Suspense>
  </>
)

export default App
