import { lazy } from 'react'

export default [
  {
    path: '/escolas',
    component: lazy(() => import('./views/List')),
    name: 'schools',
    exact: true,
    title: 'Lista de Escolas',
  },
  {
    path: '/escolas/contrato/:codeId',
    component: lazy(() => import('./views/Contract')),
    name: 'contractSchool',
    exact: true,
    title: 'Lista de Escolas',
  },
]
