import {} from './actionsTypes'

const INITIAL_STATE = {}

const schools = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  default:
    return state
  }
}

export default schools
