import { useCallback, useState } from 'react'
import { LogOut as LogOutIcon } from 'react-feather'
import { useSelector } from 'react-redux'
import { usePopper } from 'react-popper'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import I18n from 'i18n-js'

import keyCodes from 'App/enums/keyCodes'
import { LARGE } from 'App/utils'
import { logout } from 'App/utils/auth'
import urls from 'App/utils/urls'

import useOnClickOutside from 'App/hooks/useOnClickOutside'
import useOnEscape from 'App/hooks/useOnEscape'

import Avatar from 'App/components/Avatar'
import Box from 'App/components/Box'
import Button from 'App/components/Button'
import DropdownItem from 'App/components/Dropdown/DropdownItem'
import Snackbar from 'App/components/Snackbar'
import Tooltip from 'App/components/Tooltip'

import useStoreUsers from 'Users/store/useStoreUsers'

import UserMenuSkeletonLoader from './UserMenuSkeletonLoader'

import styles from './UserMenu.module.scss'

const DROPDOWN_OFFSET_DISTANCE = 8

const UserMenu = ({ isLoading, hasError }) => {
  const [
    triggerReference,
    setTriggerReference,
  ] = useState(null)
  const [
    popperDropdown,
    setPopperDropdown,
  ] = useState(null)
  const [
    dropdownOpen,
    setDropdownOpen,
  ] = useState(false)
  const user = useSelector((state) => state.users.loggedUser)

  const { getUserInfo } = useStoreUsers()

  const {
    styles: popperStyles,
    attributes: popperAttributes,
  } = usePopper(triggerReference, popperDropdown, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [
            0,
            DROPDOWN_OFFSET_DISTANCE,
          ],
        },
      },
    ],
  })

  const handleToggleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const handleToggleDropdownKeyPress = (event) => {
    const key = event.which

    if (key === keyCodes.return || key === keyCodes.space) {
      setDropdownOpen(!dropdownOpen)
    }
  }

  const handleCancel = useCallback(() => {
    setDropdownOpen(false)
    triggerReference.focus()
  }, [triggerReference])

  useOnClickOutside(dropdownOpen, popperDropdown, handleCancel)
  useOnEscape(dropdownOpen, handleCancel)

  if (isLoading) {
    return <UserMenuSkeletonLoader />
  }

  if (!isLoading && hasError) {
    return (
      <>
        <Snackbar
          className={styles.error}
          variation="danger"
        >
          <Button
            data-for="user-menu-error-tooltip"
            size="small"
            variation="danger"
            data-tip
            onClick={getUserInfo}
          >
            {I18n.t('commons.actions.reload')}
          </Button>
        </Snackbar>

        <Tooltip id="user-menu-error-tooltip">
          {I18n.t('app.userMenu.messages.failedGetInfo')}
        </Tooltip>
      </>
    )
  }

  return (
    <>
      <div
        ref={setTriggerReference}
        className={clsx(
          styles.user,
          'sas-user-menu',
        )}
        role="button"
        tabIndex="0"
        title={user.name}
        onClick={handleToggleDropdownClick}
        onKeyPress={handleToggleDropdownKeyPress}
      >
        <Avatar
          className={styles.avatar}
          imageSrc={user.profilePicture && `${
            urls.PORTAL_API
          }/storage/files/${user.profilePicture}`}
          size={LARGE}
          text={user.name}
        />

        <div className={styles.contentContainer}>
          <span className={styles.profileName}>{user.profileName}</span>

          <div className={styles.info}>
            <strong className={styles.userName}>{user.name}</strong>

            <span className={styles.schoolName}>{user.schoolName}</span>
          </div>
        </div>
      </div>

      {dropdownOpen && (
        <Box
          ref={setPopperDropdown}
          className={clsx(
            styles.dropdown,
            'sas-user-menu-dropdown',
          )}
          elevation={3}
          style={popperStyles.popper}
          isSquare
          {...popperAttributes.popper}
        >
          <div className={styles.details}>
            <span className={styles.profileName}>{user.profile}</span>

            <strong className={styles.userName}>{user.name}</strong>

            <span className={styles.schoolName}>{user.schoolName}</span>
          </div>

          <DropdownItem
            color="primary"
            prepend={<LogOutIcon />}
            onClick={logout}
          >
            {I18n.t('commons.actions.logout')}
          </DropdownItem>
        </Box>
      )}
    </>
  )
}

UserMenu.propTypes = {
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
}

UserMenu.defaultProps = {
  hasError: false,
  isLoading: false,
}

export default UserMenu
