import { useEffect } from 'react'

const useOnClickOutside = (
  isActive,
  refElement,
  onClickOutside,
  refTrigger = null,
) => {
  useEffect(() => {
    const handle = (event) => {
      if (
        refElement
        && !refElement.contains(event.target)
        && (!refTrigger || !refTrigger.contains(event.target))
      ) {
        onClickOutside(event)
      }
    }

    if (isActive) {
      document.addEventListener('mousedown', handle)
      document.addEventListener('touchstart', handle)
    }

    return () => {
      document.removeEventListener('mousedown', handle)
      document.removeEventListener('touchstart', handle)
    }
  }, [
    isActive,
    onClickOutside,
    refElement,
    refTrigger,
  ])
}

export default useOnClickOutside
